import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { articleStyle } from '../../components/Layout/styles';
import Layout from  '../../components/Layout';

import './styles.css';

class BlogIndex extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;

    const listOfPosts = posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug;
      const author = 'Dorian Puła';

      return (
        <div key={node.fields.slug}>
          <h3>
            <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
              {title}
            </Link>
          </h3>
          <small>
            By {author} on {node.frontmatter.date}
          </small>
          <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </div>
      );
    });

    return (
      <Layout>
        <div className='span7'>
          <Helmet title='Blog' />
          <article css={articleStyle}>
            <h1>Blog</h1>
            {listOfPosts}
          </article>
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
